import * as React from 'react'
import { Helmet } from 'react-helmet'

const ArchiveHomepage = (): React.ReactElement => (
  <Helmet>
    <meta
      httpEquiv="refresh"
      content={'0;url=https://archive.ichimura.ed.jp/blog/'}
    />
  </Helmet>
)

export default ArchiveHomepage
